import collapse from "bootstrap/js/dist/collapse";
import SimpleLightbox from "simplelightbox";
import Macy from "macy";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/400-italic.css";
import "@fontsource/montserrat/500-italic.css";
import "@fontsource/montserrat/600-italic.css";
import "@fontsource/montserrat/700-italic.css";
import "@fontsource/montserrat/800-italic.css";

// ===================== Navbar =====================

$(window).scroll(function() {
  if ($(document).scrollTop() > 25) {
    $('.navbar').addClass('scrolled');
  }
  else {
    $('.navbar').removeClass('scrolled');
  }
});

$('nav').on('show.bs.collapse', function () {
  $('.navbar').addClass('navbar-toggled-show');
  if(!$(document).scrollTop() == 0) {
    $('.navbar').removeClass('scrolled');
  }
})

$('nav').on('hidden.bs.collapse', function () {
  $('.navbar').removeClass('navbar-toggled-show');
  $('.navbar').addClass('scrolled');
})

$(".nav__menu a").on("click", function(event) {
  const $scrollTarget = $(event.target.dataset.scrollTarget);
  const $navToggler = $(".navbar-toggler");

  if($navToggler.has(":visible").length) {
    $navToggler.click();
  }

  if($scrollTarget.length !== 0) {
    $("html, body").stop().animate({scrollTop: $scrollTarget.offset().top - 75 }, '500');
  }
  return false;
});

$(".navbar-brand").click(function(){
  const $navToggler = $(".navbar-toggler")

  if($navToggler.has(":visible").length && $navToggler.not(".collapsed").length) {
    $navToggler.click();
  }
});

// ===================== Animation ===================== 

function isElementInViewport (el) {

  if (typeof jQuery === "function" && el instanceof jQuery) {
      el = el[0];
  }

  const rect = el.getBoundingClientRect();

  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const translateTargetSelectors = [
  ".animate__translate-into-view",
  ".animate__translate-into-view-from-left",
  ".animate__translate-into-view-from-right"
];

$(document).ready(function() {
  $(translateTargetSelectors.join(", ")).each(function(){
    if (isElementInViewport(this)) {
      $(this).css('transform', 'unset');
    }
  });

  $(window).on('scroll', function() {
    $(translateTargetSelectors.join(", ")).each(function(){
      if (isElementInViewport(this)) {
        $(this).css('transform', 'unset');
      }
    });
  });
});

// ===================== Global Site Navigation ===================== 

$(".btn__scroll-to-target").on("click", function(event) {
  const $scrollTarget = $(event.target.dataset.scrollTarget);

  if($scrollTarget.length !== 0) {
    $("html, body").stop().animate({scrollTop: $scrollTarget.offset().top - 75 }, '500');
  }
  return false;
});

$(document).ready(function() {

  $("#contact-form").on("submit", function(event) {

    event.preventDefault();

    let $form = $(this);
    let $feedbackMessage = $('#contact-form__feedback-message');
    let $btn = $(".contact-form__submit-btn")
    let $spinner = $btn.find(".contact-form__submit-btn-spinner")
    
    $feedbackMessage.text("");
    $feedbackMessage.removeClass("contact-form__feedback-message--shown contact-form__feedback-message-error");
    $spinner.removeClass("d-none");
    $btn.html($btn.children());

    setTimeout( function() {
      $.ajax({
        url: "!/forms/contact-form",
        method: $form.attr('method'),
        data: $form.serialize(),
        success: function(response) {
          $spinner.addClass("d-none");
          $btn.prepend("Absenden");
          if (response?.success){
            $feedbackMessage.addClass("contact-form__feedback-message--shown");
            $feedbackMessage.text('Vielen Dank für Ihre Mitteilung!');
            $form[0].reset();
          } else {
            $feedbackMessage.addClass("contact-form__feedback-message--shown contact-form__feedback-message-error");
            $feedbackMessage.text('Ihre Mitteilung kann derzeit leider nicht übermittelt werden!');
          }
        },
        error: function(response) {
          $spinner.addClass("d-none");
          $btn.prepend("Absenden");
          $feedbackMessage.addClass("contact-form__feedback-message--shown contact-form__feedback-message-error");
          $feedbackMessage.text('Ihre Mitteilung kann derzeit leider nicht übermittelt werden!');
        }
      });

    }, 500);

    return false;
  }); 
});

// ===================== Macy image grid ===================== 

const $macyGridContainer = $('#macy-grid__container');

if($macyGridContainer.length === 1) {
  const macyInstance = Macy({
    container: '#macy-grid__container',
    waitForImages: false,
    mobileFirst: true,
    margin: 5,
    columns: 1,
    breakAt: {
      700: 2,
      1200: 3
    }
  });
  // ===================== Simple Lightbox image gallery =====================
  
  new SimpleLightbox('#macy-grid__container a', {
    overlayOpacity: 0.9,
    showCounter: false
  });
}

